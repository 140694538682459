$color-black-platinum: #030200;
$color-black: #000000;
$color-platinium: #E5E4E2;
$color-red: #CC0000;
$color-primary-black: #636366;
$color-primary-gray: #D9D9D9;
$color-secondary-black: #8E8E93;
$color-disabled-black: #C7C7CC;
$color-border-black: #F2F2F7;
$family-orbitron: 'Orbitron';
$family-montserrat: 'Montserrat';
$family-six-caps: 'Six Caps';
$family-arm-hmk: "Arm Hmks Bebas Neue";
$family-halv-neue: "Halvetica-Neue";
$family-halvetica-Neue-75bd: "Halvetica-Neue-75bd";
$family-zekton: "Zekton";
$mediaXXLMaxWidth: 1920px;
$mediaXLMaxWidth: 1660px;
$mediaLMaxWidth: 1390px;
$mediaSMaxWidth: 1260px;
$mediaMaxWidth: 991px;
$mediaBp1Width: 768px;
$mediaBp2Width: 650px;
$mediaBp3Width: 540px;
$mediaMinWidth: 480px;
$mediaMin1Width: 425px;
$mediaSmallWidth: 375px;