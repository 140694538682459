@import "./../../../variables";

.en {
  font-family: $family-orbitron;
  background-color: $color-platinium;
  padding: 0.375rem 5.875rem;
  box-shadow: 2px 2px 0px #000000;
  font-size: 1rem;
  border: 1px solid $color-black-platinum;
  cursor: pointer;
  -webkit-text-fill-color: black;
  transform: translate(-1px, -1px);
  transition: 0.3s ease;
  border-radius: 2px;
}

.en:hover {
  box-shadow: 0 0 0 #000;
  -webkit-transform: translate(1px, 1px);
  transform: translate(1px, 1px);
}
.ru {
  font-family: $family-zekton;
  background-color: $color-platinium;
  padding: 0.375rem 5.875rem;
  box-shadow: 2px 2px 0px #030200;
  font-size: 1rem;
  font-weight: 400;
  border: 1px solid $color-black-platinum;
  cursor: pointer;
  -webkit-text-fill-color: #030200;
  transform: translate(-1px, -1px);
  transition: 0.3s ease;
  border-radius: 2px;
}
.ru:hover {
  box-shadow: 0 0 0 #000;
  -webkit-transform: translate(1px, 1px);
  transform: translate(1px, 1px);
}
.am {
  font-family: $family-halv-neue;
  background-color: $color-platinium;
  padding: 0.375rem 5.875rem;
  box-shadow: 2px 2px 0px #030200;
  font-size: 1rem;
  font-weight: 400;
  border: 1px solid $color-black-platinum;
  cursor: pointer;
  -webkit-text-fill-color: #030200;
  transform: translate(-1px, -1px);
  transition: 0.3s ease;
  border-radius: 2px;
}
.am:hover {
  box-shadow: 0 0 0 #000;
  -webkit-transform: translate(1px, 1px);
  transform: translate(1px, 1px);
}

@media only screen and (max-width: $mediaBp3Width) {
  .en {
    padding: 0.375rem 4.5rem;
    width: 100%;
  }
  .ru {
    padding: 0.375rem 4.5rem;
    width: 100%;
  }
  .am {
    padding: 0.375rem 4.5rem;
    width: 100%;
  }
}
