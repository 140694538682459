@import "./../../variables";

.main {
  font-family: $family-halv-neue;
  width: fit-content;
  transition: 0.3s;
  position: relative;

  &:active {
    box-shadow: none;
  }

  .dropDownContainer {
    width: 100%;
    margin: 0 auto;
    box-shadow: 2px 2px 0px #000000;
  }

  .dropDownContainer:hover {
    box-shadow: 0 0 0 #000;
  }

  .dropDownListActive {
    height: 100px;
    overflow: auto;
  }

  .dropDownListContainer {
    overflow: hidden;
    opacity: 0;
    transition: opacity 0.3s ease;
    width: 77px;
    height: 0;
    position: absolute;

    &.active {
      opacity: 1;
      height: 65px;
      box-shadow: 2px 2px 0px #000000;
      overflow: visible;
      height: auto;
    }
  }

  .arrowIcon {
    width: 10px;
    height: 5px;
    transition: 0.3s;
    transform: rotate(0);
  }

  .arrowActive {
    transition: 0.3s;
    transform: rotate(-180deg);
  }

  .dropDownHeader {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    background: #e5e4e2;
    border: 1px solid #030200;
    box-shadow: 2px 2px 0px #000000;
    transform: translate(-1px, -1px);
    position: relative;
    border-radius: 2px;
    width: 78px;
    height: 34px;
    transition: 0.3s;
    cursor: pointer;
    transition: 0.3s ease;

    &:hover {
      box-shadow: none;
      -webkit-transform: translate(1px, 1px);
      transform: translate(1px, 1px);
    }
  }
  .dropDownList {
    padding: 0;
    margin: 0;
    background: #e5e4e2;
    border: 1px solid #030200;
    box-shadow: 2px 2px 0px #000000;
    border-radius: 2px;
  }

  .listItem {
    font-family: $family-halv-neue;
    border-bottom: 1px solid #636366;
    height: 32px;
    list-style: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

@media only screen and (max-width: $mediaBp1Width) {
  .main {
    .dropDownHeader {
      &:hover {
        box-shadow: 2px 2px 0px #000000;
        transform: translate(-1px, -1px);
        -webkit-transform: translate(-1px, -1px);
      }

      &:active {
        box-shadow: none !important;
        -webkit-transform: translate(1px, 1px);
        transform: translate(1px, 1px);
      }
    }
  }
}
