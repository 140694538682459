.ourService > div:nth-child(2) {
  width: 100%;
  display: none;
}
.ourService > div:nth-child(1) {
  width: 100%;
  display: none;
}

.App {
  position: fixed;
  left: 50%;
  top: 135%;
  transform: translate(-50%, -50%);
  z-index: 500;
  display: flex;
  justify-content: center;
  align-items: center;
}

.button {
  background-color: transparent;
  border: none;
}
.button_prev img {
  height: 40px;
}
.button_next img {
  height: 40px;
}
.carousel_1 {
  position: relative;
  width: 1020px;
  height: 1020px;
  border: 2px solid;
  border-radius: 50%;
  transition: 0.4s;
  transform-origin: center center;
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
}

.carousel {
  position: absolute;
  width: 747px;
  height: 747px;
  border: 2px solid;
  border-radius: 50%;
  transition: 0.3s ease-in;
  transform-origin: center center;
}

.carouselItemActive {
  color: #000 !important;
}
.carouselItem {
  cursor: pointer;
  color: #898988;
}

.star {
  border-radius: 50%;
  top: 50%;
  left: 50%;
  position: absolute;
  margin-top: -13px;
  margin-left: -13px;
  width: 26px;
  height: 26px;
  background: black;
  border: 1px solid black;
}

.star.star4 {
  transform: translateY(-510px);
}

.num {
  font-family: "Orbitron";
  font-weight: 600;
  border-radius: 50%;
  top: 50%;
  position: absolute;
  font-size: 120px;
  border: 0;
}

.num.num1 {
  transform: translateY(-510px) rotate(0);
  background-color: transparent !important;
  -webkit-tap-highlight-color: transparent !important;
}

.num.num2 {
  background-color: transparent !important;
  -webkit-tap-highlight-color: transparent !important;
  transform: translate(310px, -380px) rotate(45deg);
}
.num.num3 {
  background-color: transparent !important;
  -webkit-tap-highlight-color: transparent !important;
  transform: translate(440px, -76px) rotate(90deg);
}
.num.num4 {
  background-color: transparent !important;
  -webkit-tap-highlight-color: transparent !important;
  transform: translate(310px, 230px) rotate(135deg);
}
.num.num5 {
  background-color: transparent !important;
  -webkit-tap-highlight-color: transparent !important;
  transform: translate(0px, 360px) rotate(180deg);
}
.num.num6 {
  background-color: transparent !important;
  -webkit-tap-highlight-color: transparent !important;
  transform: translate(-310px, 230px) rotate(225deg);
}
.num.num7 {
  background-color: transparent !important;
  -webkit-tap-highlight-color: transparent !important;
  transform: translate(-440px, -70px) rotate(270deg);
}
.num.num8 {
  background-color: transparent !important;
  -webkit-tap-highlight-color: transparent !important;
  transform: translate(-435px, -70px) rotate(270deg);
}
.transition {
  transition: margin-left 0.3s ease-in;
}
.offset {
  margin-left: -20%;
}
.transition_right {
  transition: margin-left 0.3s ease-in;
}
.offset_right {
  margin-left: 20%;
}

.buttons_service {
  position: absolute;
  z-index: 999;
}

.buttons_service button:first-child {
  position: absolute;
  width: 34px;
  height: 75px;
  border: none;
  font-size: 50px;
  background-color: transparent;
  transform: matrix(-1, 0, 0, 1, 0, 0);
}
.buttons_service button:last-child {
  position: absolute;
  right: 0;
  width: 34px;
  height: 75px;
  border: none;
  background-color: transparent;
  font-size: 50px;
  transform: matrix(-1, 0, 0, 1, 0, 0);
}

.title_name {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
}
.button_next {
  border: none;
  z-index: 9;
  background-color: transparent;
  right: 85px;
  position: absolute;
  cursor: pointer;
  top: 50%;
  transform: translateY(-50%);
}
.button_prev {
  border: none;
  z-index: 9;
  background-color: transparent;
  left: 85px;
  position: absolute;
  cursor: pointer;
  top: 50%;
  transform: translateY(-50%) rotate(180deg);
}

.container {
  width: 320%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.left_trans {
  margin-left: -100%;
  transition: 0.5s ease-in;
}

.border_style {
  position: fixed;
  width: 260px;
  height: 5px;
  background-color: #cc0000;
  left: 50%;
  bottom: -70px;
  transform: translate(-50%, -50px);
}

.transform_new {
  left: 50%;
}

.en .single_card {
  font-family: "Six Caps";
  font-style: normal;
  font-weight: 400;
  font-size: 100px;
  line-height: 112px;
  margin-right: -1px;
  letter-spacing: 1px;
  color: #8e8e93;
  display: flex;
  font-feature-settings: "case" on;
  width: 80%;
}
.en .title_active {
  font-family: "Six Caps";
  font-style: normal;
  font-weight: 400;
  font-size: 136px;
  line-height: 160px;
  text-align: center;
  letter-spacing: 6px;
  margin-right: -6px;
  text-transform: uppercase;
  color: #030200;
  width: 70%;
  display: flex;
  justify-content: center;
  font-feature-settings: "case" on;
}

.ru .single_card {
  font-family: "Arm Hmks Bebas Neue";
  font-style: normal;
  font-weight: 400;
  font-size: 90px;
  line-height: 118px;
  margin-right: -1px;
  letter-spacing: 2px;
  color: #8e8e93;
  display: flex;
  width: 90%;
  font-feature-settings: "case" on;
  white-space: pre-line;
}
.ru .title_active {
  font-family: "Arm Hmks Bebas Neue";
  font-style: normal;
  font-weight: 400;
  font-size: 128px;
  line-height: 136px;
  text-align: center;
  margin-right: -6px;
  letter-spacing: 6px;
  text-transform: uppercase;
  color: #030200;
  width: 80%;
  display: flex;
  justify-content: center;
  font-feature-settings: "case" on;
}

.am .single_card {
  font-family: "Arm Hmks Bebas Neue";
  font-style: normal;
  font-weight: 400;
  font-size: 100px;
  line-height: 112px;
  margin-right: -1px;
  letter-spacing: 1px;
  color: #8e8e93;
  display: flex;
  width: 80%;
  font-feature-settings: "case" on;
}
.am .title_active {
  font-family: "Arm Hmks Bebas Neue";
  font-style: normal;
  font-weight: 400;
  font-size: 136px;
  line-height: 144px;
  text-align: center;
  margin-right: -6px;
  letter-spacing: 6px;
  text-transform: uppercase;
  color: #030200;
  width: 70%;
  display: flex;
  justify-content: center;
  font-feature-settings: "case" on;
}
.en .active_width {
  width: 95%;
}
.ru .active_width {
  width: 90%;
}
.am .active_width {
  width: 90%;
}

.nonde {
  opacity: 0 !important;
}

.card_wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.transition_right {
  right: -15%;
}

.card_wrapper > div:nth-child(1) {
  display: flex;
  justify-content: flex-end;
}

.card_wrapper > div:nth-child(3) {
  display: flex;
  justify-content: flex-start;
}

@media only screen and (max-width: 1920px) {
  .App {
    top: 140%;
  }
  .en .active_width {
    width: 100%;
  }
  .ru .active_width {
    width: 90%;
  }
  .am .active_width {
    width: 80%;
  }

  .en .single_card {
    font-size: 72px;
    line-height: 80px;
    margin-right: -3px;
    letter-spacing: 3px;
    color: #8e8e93;
    display: flex;
    width: 80%;
  }
  .en .title_active {
    font-size: 94px;
    line-height: 102px;
    text-align: center;
    margin-right: -6px;
    letter-spacing: 6px;
    color: #030200;
    width: 70%;
  }

  .ru .single_card {
    font-size: 70px;
    line-height: 80px;
    margin-right: -1px;
    letter-spacing: 1px;
    width: 90%;
  }
  .ru .title_active {
    font-size: 94px;
    line-height: 102px;
    text-align: center;
    margin-right: -6px;
    letter-spacing: 6px;
    color: #030200;
    width: 80%;
  }

  .am .single_card {
    font-size: 68px;
    line-height: 74px;
    letter-spacing: 3px;
    width: 80%;
  }
  .am .title_active {
    font-size: 88px;
    line-height: 96px;
    text-align: center;
    margin-right: -6px;
    letter-spacing: 6px;
    color: #030200;
    width: 70%;
  }
}

@media only screen and (max-width: 1750px) {
  .button_next {
    right: 40px;
  }
  .button_prev {
    left: 40px;
  }
  .en .active_width {
    width: 95%;
  }
  .ru .active_width {
    width: 75%;
  }
  .am .active_width {
    width: 70%;
  }

  .en .single_card {
    font-size: 68px;
    line-height: 76px;
    margin-right: -3px;
    letter-spacing: 3px;
    width: 80%;
  }
  .en .title_active {
    font-size: 80px;
    line-height: 104px;
    margin-right: -3px;
    letter-spacing: 3px;
    width: 75%;
  }

  .ru .single_card {
    font-size: 57px;
    line-height: 68px;
    margin-right: 1px;
    letter-spacing: 1px;
    width: 90%;
  }
  .ru .title_active {
    font-size: 60px;
    line-height: 68px;
    margin-right: -3px;
    letter-spacing: 3px;
    width: 80%;
  }

  .am .single_card {
    font-size: 60px;
    line-height: 68px;
    margin-right: -3px;
    letter-spacing: 3px;
    width: 80%;
  }
  .am .title_active {
    font-size: 60px;
    line-height: 68px;
    margin-right: -3px;
    letter-spacing: 3px;
    width: 70%;
  }

  .App {
    left: 50%;
    top: 50%;
    transform: translate(-50%, 17%);
  }
  .carousel_1 {
    width: 896px;
    height: 896px;
  }

  .carousel {
    width: 656px;
    height: 656px;
  }

  .star.star4 {
    transform: translateY(-448px);
  }

  .num {
    font-size: 100px;
  }

  .num.num1 {
    transform: translateY(-448px) rotate(0);
  }
  .num.num2 {
    transform: translate(270px, -330px) rotate(45deg);
  }

  .num.num3 {
    transform: translate(380px, -65px) rotate(90deg);
  }
  .num.num4 {
    transform: translate(260px, 210px) rotate(135deg);
  }
  .num.num5 {
    transform: translate(0, 318px) rotate(180deg);
  }
  .num.num6 {
    transform: translate(-270px, 210px) rotate(225deg);
  }
  .num.num7 {
    transform: translate(-380px, -65px) rotate(270deg);
  }
}
@media only screen and (max-width: 1450px) {
  .App {
    transform: translate(-50%, 15%);
  }
  .en .active_width {
    width: 85%;
  }
  .ru .active_width {
    width: 80%;
  }
  .am .active_width {
    width: 75%;
  }

  .en .single_card {
    font-size: 48px;
    line-height: 56px;
    margin-right: -3px;
    letter-spacing: 3px;
    width: 80%;
  }
  .en .title_active {
    font-size: 60px;
    line-height: 68px;
    margin-right: -3px;
    letter-spacing: 3px;
    width: 70%;
  }

  .ru .single_card {
    font-size: 55px;
    line-height: 68px;
    letter-spacing: 0px;
    margin-right: 0px;
    width: 90%;
  }
  .ru .title_active {
    font-size: 60px;
    line-height: 68px;
    margin-right: -3px;
    letter-spacing: 3px;
    width: 80%;
  }

  .am .single_card {
    font-size: 55px;
    line-height: 68px;
    margin-right: -2px;
    letter-spacing: 2px;
    width: 80%;
  }
  .am .title_active {
    font-size: 60px;
    line-height: 68px;
    margin-right: -3px;
    letter-spacing: 3px;
    width: 70%;
  }
}

@media only screen and (max-width: 1300px) {
  .container {
    top: 45%;
  }

  .en .single_card {
    font-size: 60px;
    line-height: 80px;
  }

  .ru .single_card {
    font-size: 48px;
    line-height: 80px;
  }

  .am .single_card {
    font-size: 53px;
    line-height: 80px;
    letter-spacing: 1px;
    margin-right: -1px;
  }
  .en .title_active {
    font-size: 80px;
    line-height: 112px;
  }
  .ru .title_active {
    font-size: 56px;
    line-height: 80px;
  }
  .am .title_active {
    font-size: 56px;
    line-height: 80px;
  }
  .button_prev {
    top: 45%;
  }
  .button_next {
    top: 45%;
  }
}

@media only screen and (max-width: 1150px) {
  .App {
    transform: translate(-50%, 15%);
  }
  .button_prev {
    display: flex;
  }
  .button_prev img {
    height: 40px;
  }

  .button_next {
    display: flex;
  }

  .button_next img {
    height: 40px;
  }

  .border_style {
    width: 130px;
  }
}

@media only screen and (max-width: 991px) {
  .ourService {
    padding: 0px 15px 18px 16px;
  }
  .ourService > div:nth-child(2) {
    width: 100%;
    display: block;
  }
  .App {
    left: 50%;
    top: 85%;
    transform: translate(-50%, 0);
    z-index: -1;
  }
  .button_prev {
    left: 16px;
  }

  .button_prev img {
    height: 40px;
  }

  .button_next {
    right: 16px;
  }

  .button_next img {
    height: 40px;
  }

  .border_style {
    width: 112px;
  }

  .single_card {
    font-size: 52px;
    line-height: 60px;
    margin-right: -5px;
    letter-spacing: 5px;
  }

  .card_wrapper {
    align-items: center;
  }

  .carousel_1 {
    width: 430px;
    height: 430px;
  }

  .carousel {
    width: 282px;
    height: 282px;
  }

  .star {
    margin-top: -6px;
    margin-left: -6px;
    width: 12px;
    height: 12px;
  }
  .star.star4 {
    transform: translateY(-215px);
  }

  .num {
    font-size: 60px;
  }

  .num.num1 {
    transform: translateY(-215px) rotate(0);
  }

  .num.num2 {
    transform: translate(125px, -165px) rotate(45deg);
  }
  .num.num3 {
    transform: translate(175px, -35px) rotate(90deg);
  }

  .num.num4 {
    transform: translate(125px, 85px) rotate(135deg);
  }
  .num.num5 {
    transform: translate(0, 135px) rotate(180deg);
  }
  .num.num6 {
    transform: translate(-125px, 85px) rotate(225deg);
  }
  .num.num7 {
    transform: translate(-175px, -40px) rotate(270deg);
  }
  .ru .title_active {
    width: 100%;
  }
  .am .title_active {
    width: 100%;
  }
  .card_wrapper div:nth-child(1) {
    display: flex;
    justify-content: center;
    opacity: 0;
    height: 0;
  }

  .card_wrapper div:nth-child(3) {
    display: flex;
    justify-content: center;
    opacity: 0;
    height: 0;
  }
  .en .active_width {
    width: 80%;
    opacity: 1 !important;
    height: auto !important;
  }
  .ru .active_width {
    width: 80%;
    opacity: 1 !important;
    height: auto !important;
  }
  .am .active_width {
    width: 75%;
    opacity: 1 !important;
    height: auto !important;
  }
}

@media only screen and (max-width: 640px) {
  .App {
    left: 50%;
    top: 80%;
    transform: translate(-50%, 0);
  }
  .container {
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .button_prev img,
  .button_next img {
    height: 32px;
  }

  .button_prev {
    left: 23px;
    height: 32px;
    top: 40%;
    transform: translateY(-50%) rotate(180deg);
  }

  .button_next {
    right: 23px;
    height: 32px;
    top: 40%;
    transform: translateY(-50%);
  }

  .en .single_card {
    font-size: 52px;
    line-height: 60px;
  }

  .ru .single_card {
    font-size: 40px;
    line-height: 48px;
  }

  .am .single_card {
    width: 100%;
    font-size: 48px;
    line-height: 56px;
  }

  .en .title_active {
    width: 75%;
    font-size: 52px;
    line-height: 60px;
    text-align: center;
    margin-right: -5px;
    letter-spacing: 5px;
    font-feature-settings: "case" on;
  }
  .ru .title_active {
    width: 100%;
    font-weight: 400;
    font-size: 40px;
    line-height: 48px;
    text-align: center;
    margin-right: -1.5px;
    letter-spacing: 1.5px;
    font-feature-settings: "case" on;
  }
  .am .title_active {
    width: 100%;
    font-weight: 400;
    font-size: 48px;
    line-height: 56px;
    text-align: center;
    margin-right: -1.5px;
    letter-spacing: 1.5px;
    font-feature-settings: "case" on;
  }

  .en .active_width {
    width: 75%;
  }
  .ru .active_width {
    width: 67%;
  }
  .am .active_width {
    width: 70%;
  }
}
@media only screen and (max-width: 375px) {
  .am .single_card {
    width: 100%;
    font-size: 44px;
    line-height: 56px;
  }

  .am .title_active {
    width: 100%;
    font-weight: 400;
    font-size: 44px;
    line-height: 56px;
    margin-right: -1px;
    letter-spacing: 1px;
  }
  .button_prev {
    left: 20px;
  }

  .button_next {
    right: 20px;
  }
}

@media only screen and (max-width: 375px) {
  .am .single_card {
    width: 100%;
    font-size: 35px;
    line-height: 42px;
  }

  .am .title_active {
    width: 100%;
    font-weight: 400;
    font-size: 35px;
    line-height: 42px;
    margin-right: -1px;
    letter-spacing: 1px;
  }
  .am .active_width {
    width: 70%;
  }
}
