@import "./../../variables";

.footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 1;
  height: 85.05px;
  padding: 0 93px;
  .background_bottom {
    background: linear-gradient(
      180deg,
      rgba(229, 228, 226, 0.1) 0%,
      rgba(229,228,226) 100%
    );
    height: 70px;
    position: fixed;
    z-index: -1;
    width: 100%;
    bottom: 0;
    left: 0;
  }
  .footerIcon {
    ul {
      display: flex;
      gap: 10px;

      li {
        padding: 7.17px;
        background-color: #030200;
        cursor: pointer;
        border-radius: 2px;
        width: 36px;
        height: 36px;
        border: none;
        transition: 0.2s ease-in;

        a {
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          img {
            width: 100%;
            height: 100%;
          }
        }
      }

      li:hover {
        background-color: #c00;
      }
    }
  }

  .footerLink {
    a {
      font-family: $family-montserrat;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 22px;
      color: $color-primary-black;
    }
    .en {
      a {
        font-family: "Orbitron" !important;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 22px;
        text-align: right;
        color: #030200;
      }
    }
    .ru {
      a {
        font-family: $family-zekton !important;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 22px;
        text-align: right;
        color: #030200;
      }
    }
    .am {
      a {
        font-family: $family-halv-neue !important;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 22px;
        text-align: right;
        color: #030200;
      }
    }
  }
}

@media only screen and (max-width:$mediaLMaxWidth) {
  .footer {
    padding: 0px 65px;

  }
}

@media only screen and (max-width: $mediaMaxWidth) {
  .footer {
    display: none;
    padding: 0;
    .background_bottom {
      opacity: 0;
      display: none;
    }
  }
}
