@import "./../../variables";

.hamburgerBack {
  background-color: #00000080;
  height: 100vh;
  position: fixed;
  z-index: 6505050505050;
  width: 0;
  bottom: 0;
  top: 106px;
  left: 0;
}

.navbar {
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 93px;
}
.hamburgerBack_bg {
  background: linear-gradient(
    180deg,
    rgba(229, 228, 226) 0%,
    rgba(229, 228, 226, 0.5) 100%
  );
  backdrop-filter: blur(4px);
  height: 70px;
  position: fixed;
  z-index: 1;
  width: 100%;
  top: 36px;
  left: 0;
}

.hamburgerBack_top {
  background: #e5e4e2;
  height: 63px;
  position: fixed;
  z-index: -1;
  width: 100%;
  top: 0;
  left: 0;
}

.nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  z-index: 10;

  .hamburger {
    display: none;
  }

  .navLogo {
    width: 136px;
    a {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    img {
      width: 100%;
      height: 100%;
    }
  }

  .en {
    display: flex;
    justify-content: space-between;
    gap: 48px;

    a {
      font-family: $family-orbitron;
      font-size: 16px;
      color: $color-black-platinum;

      span {
        color: $color-red;
      }
    }
  }
  .ru {
    display: flex;
    justify-content: space-between;
    gap: 48px;

    a {
      font-family: $family-zekton;
      font-size: 16px;
      color: $color-black-platinum;

      span {
        color: $color-red;
      }
    }
  }
  .am {
    display: flex;
    justify-content: space-between;
    gap: 48px;

    a {
      font-family: $family-halv-neue;
      font-size: 16px;
      color: $color-black-platinum;

      span {
        color: $color-red;
      }
    }
  }
}

// media css

.active_border {
  border-bottom: 1px solid #000000;
}
.active_border p {
  border-bottom: 1px solid #000000;
}
.navbar .menu {
  display: flex;
  justify-content: space-between;
  width: 39%;
  list-style: none;
}

.navbar .menu a {
  text-decoration: none;
  font-size: 22px;
  color: #fff;
}

.nav_container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  position: relative;
  z-index: 99;
}

.main_container {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.nav_logo {
  width: 100%;
  height: 70%;
}

.nav_menu {
  display: flex;
  list-style: none;
  text-align: center;
  justify-content: space-between;
  align-items: center;
  gap: 48px;
}

.nav_menu > div:first-child {
  display: flex;
  list-style: none;
  text-align: center;
  justify-content: space-between;
}

.nav_links {
  color: #fff;
  text-decoration: none;
  height: 100%;
  font-size: 22px;
  position: relative;
}

.nav_links::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 0.5px;
  background-color: #000000;
  bottom: -0.5px;
  left: 0;
  transform-origin: right;
  transform: scaleX(0);
  transition: transform 0.3s ease-in-out;
}

.nav_links:hover::before {
  transform-origin: left;
  transform: scaleX(1);
}

.fa-code {
  margin-left: 1rem;
}

.nav_item {
  margin-right: 1rem;
}

.nav_item.active {
  color: #fff;
  border: 1px solid #fff;
}

.nav_icon {
  display: none;
  width: 36px;
  height: 36px;
}

.btn {
  width: 100%;
  height: 100%;
  cursor: pointer;
  background: transparent;
  border-radius: 50%;
  border: none;
  outline: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 12px;
}

.btn span {
  width: 36px;
  height: 2px;
  background: #000000;
  border-radius: 2px;
  overflow: hidden;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}

.btn span:nth-child(1) {
  -webkit-animation: span-first-off 0.2s ease-in-out forwards;
  animation: span-first-off 0.2s ease-in-out forwards;
}

.btn span:nth-child(2) {
  -webkit-animation: span-first-off 0.2s ease-in-out forwards;
  animation: span-third-off 0.2s ease-in-out forwards;
}

.btn.on span:nth-child(1) {
  -webkit-animation: span-first-on 0.2s ease-in-out forwards;
  animation: span-first-on 0.2s ease-in-out forwards;
}

.btn.on span:nth-child(2) {
  -webkit-animation: span-first-on 0.2s ease-in-out forwards;
  animation: span-third-on 0.2s ease-in-out forwards;
}

@keyframes span-first-on {
  0% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }

  30% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }

  100% {
    -webkit-transform: translate(1px, 8px) rotate(-45deg);
    transform: translate(1px, 8px) rotate(-45deg);
  }
}

@keyframes span-first-off {
  0% {
    transform: translate(1px, 8px) rotate(-45deg);
    -webkit-transform: translate(1px, 8px) rotate(-45deg);
  }

  30% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0) rotate(0deg);
  }

  100% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }
}

@keyframes span-third-on {
  0% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }

  30% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }

  100% {
    -webkit-transform: translate(1px, 8px) rotate(45deg);
    transform: translate(1px, -6px) rotate(45deg);
  }
}

@keyframes span-third-off {
  0% {
    -webkit-transform: translate(1px, 8px) rotate(-45deg);
    transform: translate(1px, -6px) rotate(45deg);
  }

  30% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0) rotate(0deg);
  }

  100% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }
}

.nav_footer {
  display: none;
}
@media only screen and (max-width: 1390px) {
  .navbar {
    padding: 0px 65px;
  }
}
@media screen and (max-width: 991px) {
  .active_border p {
    display: inline;
  }
  .navbar {
    height: 50px;
    padding: 0;
  }
  .hamburgerBack_bg {
    height: 50px;
    top: 0;
  }
  .hamburgerBack_top {
    height: 50px;
  }
  .hamburgerBack {
    background-color: #00000080;
    height: 110vh;
    position: fixed;
    z-index: 100;
    width: 110%;
    bottom: 0;
    top: 50px;
    left: 0;
  }

  .langMobile {
    display: flex !important;
    position: relative;
  }

  .nav_menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    position: absolute;
    top: 90px;
    height: 0;
    transition: height 0.3s linear;
    left: 0;
    opacity: 1;
    transition: all 1s ease;
    overflow: hidden;
    gap: 0;
    opacity: 0;
  }

  .nav_menu.active {
    position: fixed;
    background: #e5e4e2;
    left: 0px;
    opacity: 1;
    z-index: 100;
    top: 50px;
    justify-content: space-between;
    border-top: 1px solid black;
    border-bottom: 1px solid black;
    display: flex;
    flex-direction: inherit;
    overflow: visible;
    opacity: 1;
    transition: height 0.3s linear;
    height: auto;
  }

  .nav_menu.active > div:first-child {
    width: 80%;
    display: flex;
    flex-direction: column;
    border-right: 1px solid black;
    text-align: start;
    padding: 24px 2.5rem;
    gap: 24px;
  }

  .nav_menu.active > div:nth-child(2) {
    display: flex;
    justify-content: center;
    height: 260px;
    padding: 24px 2.5rem;
  }

  .nav_menu.active div a {
    color: #000000;
  }

  .nav_item .active {
    border: none;
  }

  .nav_item {
    display: inline-block;
  }

  .nav_menu {
    text-align: start;
  }

  .nav_icon {
    display: block;
    cursor: pointer;
    z-index: 99;
  }

  .nav_footer {
    display: block;
  }

  .nav_footer > div {
    display: flex !important;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: self-start;
    gap: 24px;
  }
}

@media screen and (max-width: $mediaBp2Width) {
  .nav_menu.active > div:first-child {
    padding: 24px 0 24px 24px;
  }

  .nav_menu.active > div:nth-child(2) {
    padding: 24px 24px;
  }
}
