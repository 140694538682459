@import "./../../variables";

.adversting {
  background-color: $color-black;
  width: 100%;
  padding: 4px;
  display: flex;
  padding: 6px 93px 6px;
  justify-content: space-around;
  align-items: center;
  z-index: 1000;

  > p:first-child {
    font-family: $family-halvetica-Neue-75bd;
    color: $color-platinium;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    padding: 0 5px;
  }
  > button:last-child {
    cursor: pointer;
    border: none;
    background: #cc0000;
    padding: 3px 16px;
    a {
      font-family: $family-halvetica-Neue-75bd;
      color: $color-platinium !important;
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 22px;
      text-align: center;
    }
  }
}
@media only screen and (max-width: $mediaLMaxWidth) {
  .adversting {
   padding: 6px 65px;
  }
}
@media only screen and (max-width: $mediaMaxWidth) {
  .adversting {
    padding: 6px 15px 6px 16px;
    justify-content: space-between;
  }
}
@media only screen and (max-width: $mediaBp1Width) {
  .adversting {
    flex-wrap: wrap;
    row-gap: 12px;
    padding: 5px 15px 5px 16px;

    > p:first-child {
      font-size: 12px;
      line-height: 16px;
    }
    > button:last-child {
      padding: 3px 12px;
      a {
        font-size: 14px;
        line-height: 20px;
      }
    }
  }
}
