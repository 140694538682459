.partners > div:nth-child(2) {
  width: 100%;
  display: none;
}
.partners > div:nth-child(1) {
  width: 100%;
  display: none;
}

.partners_page {
  transform: translate(-50%, -50%);
  position: absolute;
  width: 100%;
  height: 100%;
  top: 50%;
  left: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.partners_App > div {
  text-align: center;
  display: flex;
  flex-direction: column;
  row-gap: 70px;
  opacity: 0.4;
}

.slider ul {
  padding: 0;
  list-style: none;
  display: flex;
  width: max-content;
  animation: runSlider 40s linear infinite;
}
.slider img {
  height: 70px;
  margin-right: 10px;
}
.slider li {
  width: 150px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.slider {
  overflow: hidden;
}
@keyframes runSlider {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(calc(-161px * 14));
  }
}

.partners_App span {
  background-color: transparent;
}

.en {
  width: 100%;
  text-align: center;
  position: absolute;
  z-index: 15;
}

.ru {
  width: 100%;
  text-align: center;
  position: absolute;
  z-index: 15;
}

.am {
  width: 100%;
  text-align: center;
  position: absolute;
  z-index: 15;
}

.en p:nth-child(1) {
  font-family: "Six Caps";
  font-style: normal;
  font-weight: 400;
  font-size: 180px;
  line-height: 237px;
  letter-spacing: 3px;
  color: #030200;
}

.en p:nth-child(2) {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 32px;
  text-align: center;
  color: #030200;
}

.ru p:nth-child(1) {
  font-family: "Arm Hmks Bebas Neue";
  font-style: normal;
  font-size: 160px;
  line-height: 200px;
  letter-spacing: 3px;
  color: #030200;
}

.ru p:nth-child(2) {
  font-family: "Montserrat";
  font-style: normal;
  font-size: 24px;
  line-height: 32px;
  text-align: center;
  color: #030200;
}

.am p:nth-child(1) {
  font-family: "Arm Hmks Bebas Neue";
  font-style: normal;
  font-weight: 400;
  font-size: 160px;
  line-height: 200px;
  letter-spacing: 3px;
  color: #030200;
}

.am p:nth-child(2) {
  font-family: "Halvetica-Neue";
  font-style: normal;
  font-weight: 350;
  font-size: 24px;
  line-height: 32px;
  text-align: center;
  color: #030200;
}
.partners_img {
  width: 99.2px;
  height: 72px;
  object-fit: contain;
  display: flex;
  align-items: center;
}

.partners_img img {
  width: 100%;
  object-fit: contain;
  height: 100%;
  opacity: 0.4;
}

.partners_img:last-of-type {
  padding-left: 0;
}

.partners_inner {
  width: 100%;
  overflow: hidden;
}

.partners_wrapper {
  display: flex;
  height: 100%;
  gap: 40px;
}

.partners_wrapper section {
  display: flex;
  gap: 47px;
  animation: swipe var(--speed) linear infinite backwards;
}

// .partners_wrapper section.leftAnim {
//   animation: swipe_1 var(--speed) linear infinite backwards;
// }

@keyframes swipe {
  from {
    transform: translate(0);
  }

  to {
    transform: translate(-100%);
  }
}

@media only screen and (max-width: 1600px) {
  .partners_App > div {
    row-gap: 34px;
  }
}
@media only screen and (max-width: 991px) {
  .partners {
    padding: 0px 15px 18px 16px;
  }
  .partners > div:nth-child(2) {
    width: 100%;
    display: block;
  }

  .en {
    width: 100%;
  }
  .ru {
    width: 100%;
  }
  .am {
    width: 100%;
  }
  .partners_page button {
    display: flex;
    justify-content: center;
    position: absolute;
    bottom: 26px;
    width: 100%;
  }

  .partners_App > div:nth-child(2) {
    margin: 15px;
  }

  .partners_App > div:nth-child(4) {
    margin: 15px;
  }

  .partners_img {
    width: 83px;
  }

  @keyframes swipe_1 {
    from {
      transform: translate3d(0, 0, 0);
    }

    to {
      transform: translate3d(-100%, 0, 0);
    }
  }

  .en button {
    width: 100%;
  }
}

@media only screen and (max-width: 600px) {
  .partners_App > div {
    row-gap: 34px;
    height: 75%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }

  .en p:nth-child(1) {
    font-family: "Six Caps";
    font-style: normal;
    font-weight: 400;
    font-size: 128px;
    line-height: 1.1;
    letter-spacing: 3px;
    color: #030200;
  }

  .en p:nth-child(2) {
    font-size: 14px;
  }

  .ru p:nth-child(1) {
    font-family: "Arm Hmks Bebas Neue";
    font-style: normal;
    font-size: 64px;
    line-height: 80px;
    line-height: 1.1;
    color: #030200;
  }

  .ru p:nth-child(2) {
    font-size: 16px;
    line-height: 22px;
  }

  .am p:nth-child(1) {
    font-family: "Arm Hmks Bebas Neue";
    font-style: normal;
    font-size: 48px;
    line-height: 56px;
    line-height: 1.1;
    color: #030200;
  }

  .am p:nth-child(2) {
    font-size: 16px;
    line-height: 22px;
  }

  .partners_App > div:nth-child(2) {
    margin: 0;
  }

  .partners_App > div:nth-child(4) {
    margin: 0;
  }
}
