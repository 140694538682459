@import "./../../variables";

.about {
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  position: absolute;
  > div:nth-child(2) {
    width: 100%;
    display: none;
  }
  > div:nth-child(1) {
    width: 100%;
    display: none;
  }
  .about_page {
    display: flex;
    justify-content: center;
    height: 100%;
    .aboutContainer {
      width: 50%;
      height: 100%;
      display: flex;
      align-items: center;
      > div {
        width: 100%;
        height: 100%;
      }
    }
  }
}

// Media css

@media only screen and (max-width: $mediaMaxWidth) {
  .about {
    padding: 0px 15px 18px 16px;
    min-height: 100%;
    display: flex;
    flex-direction: column;
    > div:nth-child(2) {
      display: block;
    }
    .about_page {
      display: block;
      justify-content: flex-start;
      overflow: scroll;

      .aboutContainer {
        width: auto !important;
        margin: 0 auto;
      }
    }
  }
}
@media only screen and (max-width: $mediaBp1Width) {
  .about {
    gap: 0;
  }
}
