.promo_area_next {
  transition: 2s;
  position: absolute;
  transform: translateY(-50%);
  z-index: -1;
  top: 120px;
  left: -7%;
}

.promo_area_back {
  transform: translateX(0);
  transition: 2s;
}

.promo_area .round_planet {
  width: 862px;
  height: 862px;
  border: 1px solid black;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  z-index: 0;
  background-image: -moz-linear-gradient(
    0deg,
    rgba(134, 117, 255, 0.02) 0%,
    rgba(255, 255, 255, 0.502) 100%
  );
  background-image: -webkit-linear-gradient(
    0deg,
    rgba(134, 117, 255, 0.02) 0%,
    rgba(255, 255, 255, 0.502) 100%
  );
  background-image: -ms-linear-gradient(
    0deg,
    rgba(134, 117, 255, 0.02) 0%,
    rgba(255, 255, 255, 0.502) 100%
  );
}

.promo_area .round_planet.planet {
  top: 51%;
}

.planet1 {
  border: 1px solid black;
  border-radius: 50%;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 1710px;
  height: 1710px;
  border: 1px solid black;
  border-bottom: transparent;
  border-right: transparent;
  border-left: transparent;
  background-color: #e5e4e2;
  z-index: 9999;
  -webkit-animation: spinnerRotate0 1.8s linear infinite;
  animation: spinnerRotate0 1.8s linear infinite;
}

.promo_area .round_planet.planet2 {
  width: 1350px;
  height: 1350px;
  background-color: #d6d6d6;
}

.promo_area .round_planet.planet3 {
  width: 990px;
  height: 990px;
  background: #e5e4e2;
  display: flex;
  justify-content: center;
  align-items: center;
}

.promo_area .round_planet.planet4 {
  width: 630px;
  height: 630px;
  -webkit-animation: spinnerRotatetwo 4s linear infinite;
  animation: spinnerRotatetwo 4s linear infinite;
  border: 1px solid black;
  border-top: transparent;
  background-color: transparent;
  border-right: transparent;
  top: 0;
  left: 0;
  position: relative;
}

.promo_area .round_planet .star {
  border-radius: 50%;
  top: 50%;
  left: 50%;
  position: absolute;
}

.promo_area .round_planet .star.star1 {
  margin-top: -13px;
  margin-left: -13px;
  width: 26px;
  height: 26px;
  background: black;
  border: 1px solid black;
  -webkit-animation: spinnerRotate 10s linear infinite;
  animation: spinnerRotate 10s linear infinite;
}

.promo_area .round_planet .star.star2 {
  -webkit-animation: spinnerRotateone 10s linear infinite;
  animation: spinnerRotateone 10s linear infinite;
  background: black;
  border: 1px solid black;
  margin-top: -13px;
  margin-left: -13px;
  width: 26px;
  height: 26px;
}

.promo_area .round_planet .star.star3 {
  background: black;
  border: 1px solid black;
  margin-top: -13px;
  margin-left: -13px;
  width: 26px;
  height: 26px;
  -webkit-animation: spinnerRotatethree 10s linear infinite;
  animation: spinnerRotatethree 10s linear infinite;
}

.promo_area .round_planet .star.star4 {
  background: black;
  border: 1px solid black;
  margin-top: -13px;
  margin-left: -13px;
  width: 26px;
  height: 26px;
  -ms-animation: spinnerRotate4 10s linear infinite;
  -webkit-animation: spinnerRotate4 10s linear infinite;
  animation: spinnerRotate4 10s linear infinite;
}

.promo_area .round_planet .star.star5 {
  -ms-animation: spinnerRotatefour 10s linear infinite;
  -webkit-animation: spinnerRotatefour 10s linear infinite;
  animation: spinnerRotatefour 10s linear infinite;
  margin-top: -13px;
  margin-left: -13px;
  width: 26px;
  height: 26px;
  background: #e5e4e2;
  border: 1px solid black;
}

.promo_area .round_planet .star.star6 {
  -webkit-animation: spinnerRotatefive 10s linear infinite;
  animation: spinnerRotatefive 10s linear infinite;
  margin-top: -13px;
  margin-left: -13px;
  width: 26px;
  height: 26px;
  background: #e5e4e2;
  border: 1px solid black;
}

.promo_area .round_planet .star.star7 {
  -webkit-animation: spinnerRotatesix 10s linear infinite;
  animation: spinnerRotatesix 10s linear infinite;
  margin-top: -13px;
  margin-left: -13px;
  width: 26px;
  height: 26px;
  background: #e5e4e2;
  border: 1px solid black;
}

.promo_area .round_planet .star.star8 {
  -webkit-animation: spinnerRotateseven 10s linear infinite;
  animation: spinnerRotateseven 10s linear infinite;
  margin-top: -13px;
  margin-left: -13px;
  width: 26px;
  height: 26px;
  background: #e5e4e2;
  border: 1px solid black;
}

.spinners {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
}

.spinner {
  position: relative;
}

.spinner_1 {
  position: absolute;
  width: 81px;
  height: 81px;
  border-radius: 50%;
  border: 1px solid black;
  background-color: transparent;
  -webkit-animation: scale-1 2s linear infinite;
  animation: scale-1 2s linear infinite;
}

@-webkit-keyframes scale-1 {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes scale-1 {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.spinner_2 {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 94px;
  height: 94px;
  border-radius: 50%;
  border: 1px solid black;
  background-color: transparent;
  animation: scale-2 2s linear 0.1s infinite;
  -webkit-animation: scale-2 2s linear 0.1s infinite;
}

@-webkit-keyframes scale-2 {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes scale-2 {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.spinner_3 {
  width: 125px;
  height: 125px;
  border-radius: 50%;
  border: 1px solid black;
  background-color: transparent;
  display: flex;
  justify-content: center;
  position: absolute;
  align-items: center;
  -webkit-animation: scale-3 2s linear 0.2s infinite;
  animation: scale-3 2s linear 0.2s infinite;
}

@-webkit-keyframes scale-3 {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes scale-3 {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.spinner_4 {
  width: 180px;
  height: 180px;
  border-radius: 50%;
  border: 1px solid black;
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-animation: scale-4 2s linear 0.3s infinite;
  animation: scale-4 2s linear 0.3s infinite;
}

@-webkit-keyframes scale-4 {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes scale-4 {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

/*spinner keyframe
  =======================================================*/

@-webkit-keyframes spinnerRotate0 {
  0% {
    -webkit-transform: rotate(360deg);
  }

  100% {
    -webkit-transform: rotate(0deg);
  }
}

@keyframes spinnerRotate0 {
  0% {
    transform: rotate(360deg);
  }

  100% {
    transform: rotate(0deg);
  }
}

@-webkit-keyframes spinnerRotate {
  0% {
    -webkit-transform: rotate(360deg) translateX(495px);
  }

  100% {
    -webkit-transform: rotate(0deg) translateX(495px);
  }
}

@keyframes spinnerRotate {
  0% {
    transform: rotate(360deg) translateX(495px);
  }

  100% {
    transform: rotate(0deg) translateX(495px);
  }
}

@-webkit-keyframes spinnerRotateone {
  0% {
    -webkit-transform: rotate(360deg) translateY(495px);
  }

  100% {
    -webkit-transform: rotate(0deg) translateY(495px);
  }
}

@keyframes spinnerRotateone {
  0% {
    transform: rotate(360deg) translateY(495px);
  }

  100% {
    transform: rotate(0deg) translateY(495px);
  }
}

@-ms-keyframes spinnerRotate4 {
  0% {
    -ms-transform: rotate(360deg) translateY(-495px);
  }

  100% {
    -ms-transform: rotate(0deg) translateY(-495px);
  }
}
@-webkit-keyframes spinnerRotate4 {
  0% {
    -webkit-transform: rotate(360deg) translateY(-495px);
  }

  100% {
    -webkit-transform: rotate(0deg) translateY(-495px);
  }
}

@keyframes spinnerRotate4 {
  0% {
    transform: rotate(360deg) translateY(-495px);
  }

  100% {
    transform: rotate(0deg) translateY(-495px);
  }
}

@-webkit-keyframes spinnerRotatetwo {
  0% {
    -webkit-transform: rotate(360deg);
  }

  100% {
    -webkit-transform: rotate(0deg);
  }
}

@keyframes spinnerRotatetwo {
  0% {
    transform: rotate(360deg);
  }

  100% {
    transform: rotate(0deg);
  }
}

@-webkit-keyframes spinnerRotatethree {
  0% {
    -webkit-transform: rotate(360deg) translateX(-495px);
  }

  100% {
    -webkit-transform: rotate(0deg) translateX(-495px);
  }
}

@keyframes spinnerRotatethree {
  0% {
    transform: rotate(360deg) translateX(-495px);
  }

  100% {
    transform: rotate(0deg) translateX(-495px);
  }
}

@-webkit-keyframes spinnerRotatefour {
  0% {
    -webkit-transform: rotate(0deg) translateX(675px);
  }

  100% {
    -webkit-transform: rotate(360deg) translateX(675px);
  }
}

@keyframes spinnerRotatefour {
  0% {
    transform: rotate(0deg) translateX(675px);
  }

  100% {
    transform: rotate(360deg) translateX(675px);
  }
}

@-ms-keyframes spinnerRotatefive {
  0% {
    -ms-transform: rotate(0deg) translateY(675px);
  }

  100% {
    -ms-transform: rotate(360deg) translateY(675px);
  }
}
@-webkit-keyframes spinnerRotatefive {
  0% {
    -webkit-transform: rotate(0deg) translateY(675px);
  }

  100% {
    -webkit-transform: rotate(360deg) translateY(675px);
  }
}

@keyframes spinnerRotatefive {
  0% {
    transform: rotate(0deg) translateY(675px);
  }

  100% {
    transform: rotate(360deg) translateY(675px);
  }
}

@-webkit-keyframes spinnerRotatesix {
  0% {
    -webkit-transform: rotate(0deg) translateY(-675px);
  }

  100% {
    -webkit-transform: rotate(360deg) translateY(-675px);
  }
}

@keyframes spinnerRotatesix {
  0% {
    transform: rotate(0deg) translateY(-675px);
  }

  100% {
    transform: rotate(360deg) translateY(-675px);
  }
}

@-webkit-keyframes spinnerRotateseven {
  0% {
    -webkit-transform: rotate(0deg) translateX(-675px);
  }

  100% {
    -webkit-transform: rotate(360deg) translateX(-675px);
  }
}

@keyframes spinnerRotateseven {
  0% {
    transform: rotate(0deg) translateX(-675px);
  }

  100% {
    transform: rotate(360deg) translateX(-675px);
  }
}

@media only screen and (max-width: 1660px) {
  .promo_area_next {
    top: 70px !important;
    left: -17% !important;
  }

  .planet1 {
    top: 50%;
    left: 50%;
    width: 1350px;
    height: 1350px;
  }

  .promo_area .round_planet.planet2 {
    width: 917px;
    height: 917px;
  }

  .promo_area .round_planet.planet3 {
    width: 682px;
    height: 682px;
  }

  .promo_area .round_planet.planet4 {
    width: 490px;
    height: 490px;
  }

  /*spinner keyframe
    =======================================================*/
  @-ms-keyframes spinnerRotate {
    0% {
      -ms-transform: rotate(360deg) translateX(341px);
    }

    100% {
      -ms-transform: rotate(0deg) translateX(341px);
    }
  }
  @-webkit-keyframes spinnerRotate {
    0% {
      -webkit-transform: rotate(360deg) translateX(341px);
    }

    100% {
      -webkit-transform: rotate(0deg) translateX(341px);
    }
  }

  @keyframes spinnerRotate {
    0% {
      transform: rotate(360deg) translateX(341px);
    }

    100% {
      transform: rotate(0deg) translateX(341px);
    }
  }
  @-ms-keyframes spinnerRotate0 {
    0% {
      -ms-transform: rotate(360deg);
    }

    100% {
      -ms-transform: rotate(0deg);
    }
  }

  @-webkit-keyframes spinnerRotate0 {
    0% {
      -webkit-transform: rotate(360deg);
    }

    100% {
      -webkit-transform: rotate(0deg);
    }
  }

  @keyframes spinnerRotate0 {
    0% {
      transform: rotate(360deg);
    }

    100% {
      transform: rotate(0deg);
    }
  }

  @-webkit-keyframes spinnerRotateone {
    0% {
      -webkit-transform: rotate(360deg) translateY(341px);
    }

    100% {
      -webkit-transform: rotate(0deg) translateY(341px);
    }
  }

  @keyframes spinnerRotateone {
    0% {
      transform: rotate(360deg) translateY(341px);
    }

    100% {
      transform: rotate(0deg) translateY(341px);
    }
  }

  @keyframes spinnerRotatetwo {
    0% {
      transform: rotate(360deg);
    }

    100% {
      transform: rotate(0deg);
    }
  }

  @-webkit-keyframes spinnerRotatethree {
    0% {
      -webkit-transform: rotate(360deg) translateX(-341px);
    }

    100% {
      -webkit-transform: rotate(0deg) translateX(-341px);
    }
  }

  @keyframes spinnerRotatethree {
    0% {
      transform: rotate(360deg) translateX(-341px);
    }

    100% {
      transform: rotate(0deg) translateX(-341px);
    }
  }

  @-webkit-keyframes spinnerRotate4 {
    0% {
      -webkit-transform: rotate(360deg) translateY(-341px);
    }

    100% {
      -webkit-transform: rotate(0deg) translateY(-341px);
    }
  }

  @keyframes spinnerRotate4 {
    0% {
      transform: rotate(360deg) translateY(-341px);
    }

    100% {
      transform: rotate(0deg) translateY(-341px);
    }
  }

  @-webkit-keyframes spinnerRotatefour {
    0% {
      -webkit-transform: rotate(0deg) translateX(458px);
    }

    100% {
      -webkit-transform: rotate(360deg) translateX(458px);
    }
  }

  @keyframes spinnerRotatefour {
    0% {
      transform: rotate(0deg) translateX(458px);
    }

    100% {
      transform: rotate(360deg) translateX(458px);
    }
  }

  @-webkit-keyframes spinnerRotatefive {
    0% {
      -webkit-transform: rotate(0deg) translateY(458px);
    }

    100% {
      -webkit-transform: rotate(360deg) translateY(458px);
    }
  }

  @keyframes spinnerRotatefive {
    0% {
      transform: rotate(0deg) translateY(458px);
    }

    100% {
      transform: rotate(360deg) translateY(458px);
    }
  }

  @-webkit-keyframes spinnerRotatesix {
    0% {
      -webkit-transform: rotate(0deg) translateY(-458px);
    }

    100% {
      -webkit-transform: rotate(360deg) translateY(-458px);
    }
  }

  @keyframes spinnerRotatesix {
    0% {
      transform: rotate(0deg) translateY(-458px);
    }

    100% {
      transform: rotate(360deg) translateY(-458px);
    }
  }

  @-webkit-keyframes spinnerRotateseven {
    0% {
      -webkit-transform: rotate(0deg) translateX(-458px);
    }

    100% {
      -webkit-transform: rotate(360deg) translateX(-458px);
    }
  }

  @keyframes spinnerRotateseven {
    0% {
      transform: rotate(0deg) translateX(-458px);
    }

    100% {
      transform: rotate(360deg) translateX(-458px);
    }
  }
}
@media only screen and (max-width: 991px) {
  .promo_area_next {
    transform: translate(-10%, -65%) !important;
  }
}
@media only screen and (max-width: 720px) {
  .promo_area_next {
    transform: translate(-3%, -87%) !important;
  }
  .planet1 {
    top: 50%;
    left: 50%;
    width: 900px;
    height: 900px;
  }

  .promo_area .round_planet.planet2 {
    width: 650px;
    height: 650px;
  }

  .promo_area .round_planet.planet3 {
    width: 484px;
    height: 484px;
  }

  .promo_area .round_planet.planet4 {
    width: 348px;
    height: 348px;
  }

  /*spinner keyframe
    =======================================================*/
  @-webkit-keyframes spinnerRotate {
    0% {
      -webkit-transform: rotate(360deg) translateX(242px);
    }

    100% {
      -webkit-transform: rotate(0deg) translateX(242px);
    }
  }

  @keyframes spinnerRotate {
    0% {
      transform: rotate(360deg) translateX(242px);
    }

    100% {
      transform: rotate(0deg) translateX(242px);
    }
  }

  @-webkit-keyframes spinnerRotate0 {
    0% {
      -webkit-transform: rotate(360deg);
    }

    100% {
      -webkit-transform: rotate(0deg);
    }
  }

  @keyframes spinnerRotate0 {
    0% {
      transform: rotate(360deg);
    }

    100% {
      transform: rotate(0deg);
    }
  }

  @-webkit-keyframes spinnerRotateone {
    0% {
      -webkit-transform: rotate(360deg) translateY(242px);
    }

    100% {
      -webkit-transform: rotate(0deg) translateY(242px);
    }
  }

  @keyframes spinnerRotateone {
    0% {
      transform: rotate(360deg) translateY(242px);
    }

    100% {
      transform: rotate(0deg) translateY(242px);
    }
  }

  @-webkit-keyframes spinnerRotatetwo {
    0% {
      -webkit-transform: rotate(360deg);
    }

    100% {
      -webkit-transform: rotate(0deg);
    }
  }

  @keyframes spinnerRotatetwo {
    0% {
      transform: rotate(360deg);
    }

    100% {
      transform: rotate(0deg);
    }
  }

  @-webkit-keyframes spinnerRotatethree {
    0% {
      -webkit-transform: rotate(3600deg) translateX(-242px);
    }

    100% {
      -webkit-transform: rotate(0deg) translateX(-242px);
    }
  }

  @keyframes spinnerRotatethree {
    0% {
      transform: rotate(360deg) translateX(-242px);
    }

    100% {
      transform: rotate(0deg) translateX(-242px);
    }
  }

  @-webkit-keyframes spinnerRotate4 {
    0% {
      -webkit-transform: rotate(360deg) translateY(-242px);
    }

    100% {
      -webkit-transform: rotate(0deg) translateY(-242px);
    }
  }

  @keyframes spinnerRotate4 {
    0% {
      transform: rotate(360deg) translateY(-242px);
    }

    100% {
      transform: rotate(0deg) translateY(-242px);
    }
  }

  @-webkit-keyframes spinnerRotatefour {
    0% {
      -webkit-transform: rotate(0deg) translateX(325px);
    }

    100% {
      -webkit-transform: rotate(360deg) translateX(325px);
    }
  }

  @keyframes spinnerRotatefour {
    0% {
      transform: rotate(0deg) translateX(325px);
    }

    100% {
      transform: rotate(360deg) translateX(325px);
    }
  }

  @-webkit-keyframes spinnerRotatefive {
    0% {
      -webkit-transform: rotate(0deg) translateY(325px);
    }

    100% {
      -webkit-transform: rotate(360deg) translateY(325px);
    }
  }

  @keyframes spinnerRotatefive {
    0% {
      transform: rotate(0deg) translateY(325px);
    }

    100% {
      transform: rotate(360deg) translateY(325px);
    }
  }

  @-webkit-keyframes spinnerRotatesix {
    0% {
      -webkit-transform: rotate(0deg) translateY(-325px);
    }

    100% {
      -webkit-transform: rotate(360deg) translateY(-325px);
    }
  }

  @keyframes spinnerRotatesix {
    0% {
      transform: rotate(0deg) translateY(-325px);
    }

    100% {
      transform: rotate(360deg) translateY(-325px);
    }
  }

  @-webkit-keyframes spinnerRotateseven {
    0% {
      -webkit-transform: rotate(0deg) translateX(-325px);
    }

    100% {
      -webkit-transform: rotate(360deg) translateX(-325px);
    }
  }

  @keyframes spinnerRotateseven {
    0% {
      transform: rotate(0deg) translateX(-325px);
    }

    100% {
      transform: rotate(360deg) translateX(-325px);
    }
  }
}
