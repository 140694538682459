@import "./variables";

@import url("https://fonts.googleapis.com/css2?family=Orbitron:wght@400;600;700;900&display=fallback");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;700;900&display=fallback");
@import url("https://fonts.googleapis.com/css2?family=Six+Caps&display=fallback");

@font-face {
  font-family: "Arm Hmks Bebas Neue";
  src: url("./../src/assets/fonts/ArmHmksBebasNeue.woff");
  font-display: fallback;
}
@font-face {
  font-family: "Halvetica-Neue";
  src: url("./../src/assets/fonts/HelveticaNeueLTARMW05-45Lt.woff");
  font-display: fallback;
}
@font-face {
  font-family: "Halvetica-Neue-75bd";
  src: url("./../src/assets/fonts/HelveticaNeueLTARMW05-75Bd.woff");
  font-display: fallback;
}

@font-face {
  font-family: "Zekton";
  src: url("./../src/assets/fonts/zekton.woff");
  font-display: fallback;
}
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  -webkit-user-select: none;
  -webkit-touch-callout: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
}

*:disabled {
  background: transparent !important;
  opacity: 1 !important;
  cursor: pointer;
  outline: none;
  color: #898988;
}

img {
  object-fit: contain;
}

#webpack-dev-server-client-overlay {
  display: none !important;
}

::-webkit-scrollbar {
  width: 0px !important;
  opacity: 0;
  display: none;
}

.osano-cm-dialog {
  background: #e5e4e2 !important;
  border: 2px solid #030200 !important;
  box-shadow: 4px 4px 0px #030200 !important;
  border-radius: 4px !important;
  font-family: "Montserrat" !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 16px !important;
  line-height: 26px !important;
  color: #030200 !important;
  display: block !important;
}
.osano-cm-close {
  outline: none !important;
}

.osano-cm-dialog__buttons {
  flex-wrap: nowrap;
  width: 100%;
}
.osano-cm-button {
  border-color: #000 !important;
  color: #000 !important;
  padding: 6px 27px !important;
  height: 34px !important;
  border: 1px solid #030200 !important;
  box-shadow: 2px 2px 0px #030200 !important;
  background-color: #5efc5b !important;
  border-radius: 2px !important;
  margin: 0 !important;
  flex: none !important;
}
.osano-cm-dialog__buttons {
  max-width: 100% !important;
  flex-direction: row-reverse;
  margin: 0 !important;
}

.osano-cm-dialog--type_bar.osano-cm-dialog--position_bottom {
  bottom: 15% !important;
  width: 40% !important;
  margin: 0 auto !important;
  flex-direction: column !important;
}
.osano-cm-link {
  color: #030200 !important;
}
.osano-cm-link:hover {
  color: #030200 !important;
}
.osano-cm-dialog__close {
  color: $color-black-platinum !important;
  background-color: $color-platinium !important;
  border: none !important;
}
.osano-cm-save {
  display: none !important;
}
.osano-cm-widget--position_right {
  right: 32px;
  bottom: 24px;
}
.osano-cm-window__widget {
  outline: none !important;
  filter: grayscale(1);
}
.osano-cm-dialog__list {
  display: none !important;
}
.osano-cm-window {
  z-index: 10000 !important;
}
.osano-cm-dialog__list {
  display: none !important;
}

body {
  overflow: hidden;
}

ul {
  list-style: none;
}

a {
  text-decoration: none;
}

.layout {
  height: 100vh;
  background-color: $color-platinium;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
}

.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  background-color: $color-platinium;
  z-index: 10000;
  width: 100%;
  animation: loading 3s forwards;
}

@keyframes loading {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 1;
  }
  80% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.fadeIn {
  height: 100%;
  -webkit-animation: 0.4s fadeIn forwards;
  animation: 0.4s fadeIn forwards;
}

.fadeOut {
  height: 100%;
  -webkit-animation: 0.4s fadeOut forwards;
  animation: 0.4s fadeOut forwards;
}
@media only screen and (max-width: $mediaLMaxWidth) {
  .osano-cm-widget--position_right {
    right: 15px;
  }
}

@media only screen and (max-width: $mediaMaxWidth) {
  
  .osano-cm-dialog--type_bar.osano-cm-dialog--position_bottom {
    width: 91% !important;
  }
  .layout > div:nth-child(2) {
    display: none;
  }

  .layout > div:last-child {
    display: none;
  }

  .osano-cm-window__widget {
    display: none !important;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translate(0px, 0px);
    transform: translate(0px, 0px);
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
    -webkit-transform: translate(0px, 0px);
    transform: translate(0px, 0px);
  }
  to {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
    opacity: 0;
  }
}

@media only screen and (max-width: $mediaBp2Width) {
  .osano-cm-dialog__buttons {
    flex-direction: column;
    gap: 10px;
  }
  .osano-cm-widget--position_right {
    right: 16px;
    bottom: 120px;
  }
  .osano-cm-button {
    height: 45px !important;
  }
}

.loading_div {
  opacity: 0;
  position: absolute;
  z-index: -1;
}
.loading_div span:nth-child(1) {
  font-family: $family-zekton;
}
.loading_div span:nth-child(2) {
  font-family: $family-orbitron;
}
.loading_div span:nth-child(3) {
  font-family: $family-montserrat;
}
.loading_div span:nth-child(4) {
  font-family: $family-six-caps;
}
.loading_div span:nth-child(5) {
  font-family: $family-arm-hmk;
}
.loading_div span:nth-child(6) {
  font-family: $family-halv-neue;
}
.loading_div span:nth-child(7) {
  font-family: $family-halvetica-Neue-75bd;
}
