@import "./../../variables";
.about {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  position: absolute;

  > div:nth-child(2) {
    width: 100%;
    display: none;
  }
  > div:nth-child(1) {
    width: 100%;
    display: none;
  }
  .about_page {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;

    .aboutContainer {
      width: 1351px;
      display: flex;
      flex-direction: column;
      row-gap: 18px;

      .firstBlock {
        display: flex;
        gap: 90px;

        .en {
          display: flex;
          > img:nth-child(1) {
            width: 495px;
            display: block;
          }
          > img:nth-child(2) {
            display: none;
          }
          > img:nth-child(3) {
            display: none;
          }
        }

        .ru {
          display: flex;
          > img:nth-child(1) {
            display: none;
          }
          > img:nth-child(2) {
            display: none;
          }
          > img:nth-child(3) {
            width: 502px;
            display: block;
          }
        }
        .am {
          display: flex;
          > img:nth-child(1) {
            display: none;
          }
          > img:nth-child(2) {
            width: 450px;
            display: block;
          }
          > img:nth-child(3) {
            display: none;
          }
        }
        .en {
          p {
            color: $color-black-platinum;
            font-family: "Montserrat";
            font-weight: 400;
            font-size: 24px;
            line-height: 32px;
            text-align: justify;
            padding-top: 230px;
            letter-spacing: 1px;
          }
        }
        .ru {
          p {
            color: $color-black-platinum;
            font-family: "Montserrat";
            font-weight: 400;
            font-size: 24px;
            line-height: 32px;
            text-align: justify;
            padding-top: 198px;
          }
        }
        .am {
          p {
            color: $color-black-platinum;
            font-family: "Halvetica-Neue";
            font-weight: 400;
            font-size: 24px;
            line-height: 32px;
            text-align: justify;
            padding-top: 235px;
          }
        }
      }

      .secondBlock {
        display: flex;
        flex-direction: column;
        row-gap: 15px;

        .secondBlockTitle {
          display: flex;
          gap: 15px;
          align-items: center;
          justify-content: space-between;

          .en {
            font-family: "Montserrat";
            font-style: normal;
            font-weight: 700;
            font-size: 28px;
            line-height: 36px;
            text-align: justify;
            letter-spacing: 1px;
            text-transform: uppercase;
            white-space: nowrap;
          }
          .ru {
            font-family: "Montserrat";
            font-style: normal;
            font-weight: 700;
            font-size: 32px;
            line-height: 40px;
            text-align: justify;
            white-space: nowrap;
          }
          .am {
            font-family: $family-halvetica-Neue-75bd;
            font-style: normal;
            font-weight: 700;
            font-size: 32px;
            line-height: 40px;
            text-align: justify;
            white-space: nowrap;
          }

          div {
            width: 100%;
            height: 1px;
            background-color: $color-black;
          }
        }

        .en {
          display: flex;
          flex-direction: column;
          row-gap: 20px;

          p {
            font-family: "Montserrat";
            color: $color-black-platinum;
            font-style: normal;
            font-weight: 400;
            font-size: 20px;
            line-height: 28px;
            text-align: justify;
            letter-spacing: 1px;
          }
        }
        .ru {
          display: flex;
          flex-direction: column;
          row-gap: 20px;

          p {
            font-family: "Montserrat";
            font-style: normal;
            font-weight: 400;
            font-size: 20px;
            line-height: 28px;
            text-align: justify;
            color: $color-black-platinum;
          }
        }
        .am {
          display: flex;
          flex-direction: column;
          row-gap: 20px;

          p {
            font-family: "Halvetica-Neue";
            font-style: normal;
            font-weight: 350;
            font-size: 20px;
            line-height: 28px;
            text-align: justify;
            color: $color-black-platinum;
          }
        }
      }
    }
  }
}

// Media css

@media only screen and (max-width: $mediaXXLMaxWidth) {
  .about {
    .about_page {
      .aboutContainer {
        width: 912px;
        row-gap: 18px;

        .firstBlock {
          gap: 40px;
          .en {
            display: flex;
            > img:nth-child(1) {
              width: 365px;
              display: block;
            }
            > img:nth-child(2) {
              display: none;
            }
            > img:nth-child(3) {
              display: none;
            }
          }
          .am {
            display: flex;
            > img:nth-child(1) {
              display: none;
            }
            > img:nth-child(2) {
              width: 312px;
            }
            > img:nth-child(3) {
              display: none;
            }
          }
          .ru {
            display: flex;
            > img:nth-child(1) {
              display: none;
            }
            > img:nth-child(2) {
              display: none;
            }
            > img:nth-child(3) {
              width: 339px;
              display: block;
            }
          }

          .en {
            p {
              font-size: 20px;
              line-height: 28px;
              padding-top: 128px;
            }
          }
          .ru {
            p {
              font-size: 20px;
              line-height: 28px;
              padding-top: 100px;
            }
          }
          .am {
            p {
              font-size: 20px;
              line-height: 28px;
              padding-top: 103px;
            }
          }
        }

        .secondBlock {
          row-gap: 15px;

          .secondBlockTitle {
            gap: 15px;
            .en {
              font-size: 24px;
              line-height: 32px;
            }
            .ru {
              font-size: 28px;
              line-height: 36px;
            }
            .am {
              font-size: 28px;
              line-height: 26px;
            }
          }

          .en {
            row-gap: 15px;

            p {
              font-size: 16px;
              line-height: 24px;
            }
          }
          .ru {
            row-gap: 15px;

            p {
              font-size: 16px;
              line-height: 24px;
            }
          }
          .am {
            row-gap: 15px;

            p {
              font-size: 16px;
              line-height: 24px;
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: $mediaXLMaxWidth) {
  .about {
    .about_page {
      .aboutContainer {
        width: 726px;
        row-gap: 18px;

        .firstBlock {
          gap: 40px;
          .en {
            display: flex;
            > img:nth-child(1) {
              width: 297px;
              display: block;
            }
            > img:nth-child(2) {
              display: none;
            }
            > img:nth-child(3) {
              display: none;
            }
          }
          .am {
            display: flex;
            > img:nth-child(1) {
              display: none;
            }
            > img:nth-child(2) {
              width: 252px;
            }
            > img:nth-child(3) {
              display: none;
            }
          }
          .ru {
            display: flex;
            > img:nth-child(1) {
              display: none;
            }
            > img:nth-child(2) {
              display: none;
            }
            > img:nth-child(3) {
              width: 273px;
              display: block;
            }
          }

          .en {
            p {
              font-size: 16px;
              line-height: 20px;
              padding-top: 94px;
            }
          }
          .ru {
            p {
              font-size: 16px;
              line-height: 22px;
              padding-top: 60px;
            }
          }
          .am {
            p {
              font-size: 16px;
              line-height: 22px;
              padding-top: 68px;
            }
          }
        }

        .secondBlock {
          row-gap: 15px;

          .secondBlockTitle {
            gap: 15px;
            .en {
              font-size: 20px;
              line-height: 24px;
            }
            .ru {
              font-size: 24px;
              line-height: 32px;
            }
            .am {
              font-size: 24px;
              line-height: 32px;
            }
          }

          .en {
            p {
              font-size: 13px;
              line-height: 18px;
            }
          }
          .ru {
            p {
              font-size: 13px;
              line-height: 18px;
            }
          }
          .am {
            p {
              font-size: 13px;
              line-height: 18px;
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: $mediaLMaxWidth) {
  .about {
    .about_page {
      .aboutContainer {
        width: 671px;
        row-gap: 16px;

        .firstBlock {
          gap: 32px;
          .en {
            display: flex;
            > img:nth-child(1) {
              width: 239px;
              display: block;
            }
            > img:nth-child(2) {
              display: none;
            }
            > img:nth-child(3) {
              display: none;
            }
          }
          .am {
            display: flex;
            > img:nth-child(1) {
              display: none;
            }
            > img:nth-child(2) {
              width: 213px;
            }
            > img:nth-child(3) {
              display: none;
            }
          }
          .ru {
            display: flex;
            > img:nth-child(1) {
              display: none;
            }
            > img:nth-child(2) {
              display: none;
            }
            > img:nth-child(3) {
              width: 267px;
              display: block;
            }
          }

          .en {
            p {
              font-size: 15px;
              line-height: 20px;
              padding-top: 96px;
            }
          }
          .ru {
            p {
              font-size: 15px;
              line-height: 20px;
              padding-top: 56px;
            }
          }
          .am {
            p {
              font-size: 15px;
              line-height: 20px;
              padding-top: 50px;
            }
          }
        }

        .secondBlock {
          row-gap: 15px;

          .secondBlockTitle {
            gap: 24px;
            .en {
              font-size: 19px;
              line-height: 24px;
            }
            .ru {
              font-size: 19px;
              line-height: 24px;
            }
            .am {
              font-size: 19px;
              line-height: 24px;
            }
          }

          .en {
            row-gap: 15px;

            p {
              font-size: 12px;
              line-height: 16px;
            }
          }
          .ru {
            row-gap: 15px;

            p {
              font-size: 12px;
              line-height: 16px;
            }
          }
          .am {
            row-gap: 15px;

            p {
              font-size: 12px;
              line-height: 16px;
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: $mediaMaxWidth) {
  .about {
    padding: 0px 15px 18px 16px;
    display: flex;
    flex-direction: column;
    gap: 18px;
    > div:nth-child(2) {
      display: block;
    }
    .about_page {
      overflow: hidden;
    }
  }
}

@media only screen and (max-width: $mediaBp1Width) {
  .about {
    gap: 0;
    .about_page {
      justify-content: flex-start;
      .aboutContainer {
        width: auto !important;
        overflow: auto;
        .firstBlock {
          flex-direction: column;
          gap: 15px;
          .en {
            p {
              padding-top: 0 !important;
            }
          }
          .ru {
            p {
              padding-top: 0 !important;
            }
          }
          .am {
            p {
              padding-top: 0 !important;
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: $mediaBp3Width) {
  .about {
    .about_page {
      .aboutContainer {
        .en {
          img {
            width: 240px;
          }
        }
        .ru {
          img {
            width: 240px;
          }
        }
        .am {
          img {
            width: 240px;
          }
        }

        .secondBlock {
          display: flex;
          flex-direction: column;
          row-gap: 24px;

          .secondBlockTitle {
            gap: 8px;
          }
        }
      }
    }
  }
}
