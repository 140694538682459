@import "./../../variables";

.home {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  justify-content: center;
  width: 100%;
  gap: 33px;
  .title_header {
    height: 200px;
    display: flex;
    align-items: flex-end;
  }
  > div:nth-child(2) {
    width: 100%;
    display: none;
  }
  > div:nth-child(1) {
    width: 100%;
    display: none;
  }

  .homeDivBtn {
    position: relative;
  }

  .am {
    font-family: $family-arm-hmk;
    display: flex;
    align-items: baseline;
    gap: 25px;

    > section {
      left: 36%;
      top: 247%;
    }

    .firstTitle {
      letter-spacing: 1.5px;
      font-size: 160px;
      font-feature-settings: "case" on;
    }

    .lastTitle {
      font-size: 160px;
      letter-spacing: 1.5px;
      color: $color-red;
      font-feature-settings: "case" on;
    }

    span {
      font-size: 80px;
      font-weight: 400;
      color: $color-black-platinum;
      font-feature-settings: "case" on;
      line-height: 110%;
      letter-spacing: 3px;
    }
  }
  .ru {
    font-family: $family-arm-hmk;
    display: flex;
    align-items: baseline;
    gap: 25px;
    > section {
      left: 35%;
      top: 260%;
    }
    .firstTitle {
      font-size: 140px;
    }

    .lastTitle {
      font-size: 140px;
      color: $color-red;
    }

    span {
      font-size: 64px;
      font-weight: 400;
      color: $color-black-platinum;
      font-feature-settings: "case" on;
      line-height: 110%;
    }
  }

  .en {
    font-family: $family-six-caps;
    display: flex;
    align-items: baseline;
    gap: 25px;

    .firstTitle {
      font-size: 180px;
      letter-spacing: 3px;
    }

    .lastTitle {
      font-size: 180px;
      letter-spacing: 3px;
      color: $color-red;
    }

    span {
      font-size: 100px;
      font-weight: 400;
      color: $color-black-platinum;
      font-feature-settings: "case" on;
      line-height: 110%;
    }
  }

  .homeBtn {
    padding: 6px 94.5px;
    border-radius: 2px;
  }
}

@media only screen and (max-width: $mediaXLMaxWidth) {
  .home {
    > div:nth-child(2) {
      position: static !important;
    }
  }
}
@media only screen and (max-width: $mediaLMaxWidth) {
  .home {
    .ru {
      .firstTitle {
        font-size: 120px;
      }

      .lastTitle {
        font-size: 120px;
      }

      span {
        font-size: 50px;
      }
    }
    .am {
      .firstTitle {
        font-size: 120px;
      }

      .lastTitle {
        font-size: 120px;
      }

      span {
        font-size: 50px;
      }
    }
  }
}
@media only screen and (max-width: $mediaSMaxWidth) {
  .home {
    .title_header{
      height: auto;
    }
    .en {
      .firstTitle {
        font-size: 140px;
      }

      .lastTitle {
        font-size: 140px;
      }

      span {
        font-size: 70px;
      }
    }
    .ru {
      .firstTitle {
        font-size: 100px;
      }

      .lastTitle {
        font-size: 100px;
      }

      span {
        font-size: 30px;
      }
    }
    .am {
      .firstTitle {
        font-size: 100px;
      }

      .lastTitle {
        font-size: 100px;
      }

      span {
        font-size: 50px;
      }
    }
  }
}

@media only screen and (max-width: $mediaMaxWidth) {
  .home {
    padding: 0px 15px 18px 16px;
    justify-content: space-between;
    gap: 0;

    > div:nth-child(2) {
      display: block;
    }

    .en {
      .firstTitle {
        font-size: 100px;
      }

      .lastTitle {
        font-size: 100px;
      }

      span {
        font-size: 50px;
      }
    }
    .ru {
      margin-left: 0px;
      .firstTitle {
        font-size: 80px;
      }

      .lastTitle {
        font-size: 80px;
      }
    }
    .am {
      gap: 10px;
      margin-left: 0px;
      .firstTitle {
        font-size: 80px;
      }

      .lastTitle {
        font-size: 80px;
      }
    }
  }
}

@media only screen and (max-width: $mediaBp1Width) {
  .home {
    .en {
      flex-direction: column;
      .firstTitle {
        font-size: 150px;
      }

      .lastTitle {
        font-size: 150px;
      }

      span {
        font-size: 80px;
      }
    }
    .ru {
      gap: 10px;
      .firstTitle {
        font-size: 60px;
      }

      .lastTitle {
        font-size: 60px;
      }

      span {
        font-size: 50px;
      }
    }
    .am {
      gap: 10px;
      .firstTitle {
        font-size: 60px;
      }

      .lastTitle {
        font-size: 60px;
      }

      span {
        font-size: 50px;
      }
    }
  }
}

@media only screen and (max-width: $mediaBp2Width) {
  .home {
    align-items: flex-start;

    .am {
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      gap: 0;
      margin-left: 0px;

      .firstTitle {
        font-size: 112px;
        line-height: 118px;
      }

      .lastTitle {
        font-size: 112px;
        line-height: 128px;
      }

      span {
        font-size: 48px;
        line-height: 56px;
      }
    }

    .ru {
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      gap: 0;
      margin-left: 0px;

      .firstTitle {
        font-size: 96px;
        line-height: 112px;
      }

      .lastTitle {
        font-size: 80px;
        line-height: 100px;
      }

      span {
        line-height: 48px;
        font-size: 40px;
      }
    }

    .en {
      display: flex;
      margin-bottom: 15px;
      align-items: flex-start;

      gap: 0;
      margin-left: 0px;

      .firstTitle {
        font-size: 140px;
      }

      .lastTitle {
        font-size: 140px;
      }

      span {
        font-size: 80px;
      }
    }

    .homeDivBtn {
      display: flex;
      justify-content: center;
      width: 100%;

      .homeBtn {
        padding: 6px 40.5px;
        border-radius: 2px;
        width: 100%;
      }
    }
  }
}

@media only screen and (max-width: $mediaSmallWidth) {
  .home {
    align-items: flex-start;
    .en {
      display: flex;
      margin-bottom: 15px;
      align-items: flex-start;

      .firstTitle {
        font-size: 125px;
        line-height: 1;
      }

      .lastTitle {
        font-size: 125px;
        line-height: 1;
      }

      span {
        font-size: 69px;
        line-height: 1;
      }
    }
  }
}
