@import "./../../variables";

.about {
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  position: absolute;
  > div:nth-child(2) {
    width: 100%;
    display: none;
  }
  > div:nth-child(1) {
    width: 100%;
    display: none;
  }
  .about_page {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    p {
      color: $color-black-platinum;
      font-family: $family-montserrat;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 26px;
      text-align: justify;
    }
    h4 {
      color: $color-black-platinum;
      font-family: $family-montserrat;
      font-style: normal;
      font-weight: 400;
      font-size: 24px;
      line-height: 32px;
      text-align: justify;
    }
    .aboutContainer {
      max-width: 830px;
      display: flex;
      flex-direction: column;
      row-gap: 18px;
      .policy_title {
        display: flex;
        align-items: center;
        gap: 8px;
        margin-bottom: 20px;
        div {
          width: 16px;
          height: 8px;
          background: #cc0000;
        }
        h2 {
          font-family: $family-six-caps;
          font-style: normal;
          font-weight: 400;
          font-size: 80px;
          line-height: 104px;
          letter-spacing: 1px;
          color: #030200;
        }
      }

      .secondBlock {
        display: flex;
        flex-direction: column;
        row-gap: 15px;

        .desc {
          .section_1 {
            display: flex;
            flex-direction: column;
            gap: 20px;
            margin-bottom: 40px;
          }
          .section_2 {
            margin-bottom: 20px;
            h4 {
              margin-bottom: 20px;
            }
            ul {
              list-style-type: disc !important;
              margin-left: 25px;
              li {
                color: $color-black-platinum;
                font-family: $family-montserrat;
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 26px;
                text-align: justify;
              }
            }
          }
          .section_3 {
            margin-bottom: 20px;
            > ul:nth-child(2) {
              margin-left: 25px;
              li {
                color: $color-black-platinum;
                font-family: $family-montserrat;
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 26px;
                text-align: justify;
              }
            }

            > ul:nth-child(3) {
              margin-left: 60px;
              li {
                color: $color-black-platinum;
                font-family: $family-montserrat;
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 26px;
                text-align: justify;
              }
            }
          }
          .section_4 {
            margin-bottom: 40px;
          }
          .section_5 {
            margin-bottom: 40px;
            h4 {
              margin-bottom: 20px;
            }
            ul {
              list-style-type: disc !important;
              margin-left: 25px;
              li {
                span {
                  font-weight: bold;
                }
                color: $color-black-platinum;
                font-family: $family-montserrat;
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 26px;
                text-align: justify;
              }
            }
          }
          .section_6 {
            margin-bottom: 40px;
            h4 {
              margin-bottom: 20px;
            }
          }
          .section_7 {
            margin-bottom: 40px;
            h4 {
              margin-bottom: 20px;
            }
          }
          .section_8 {
            margin-bottom: 40px;
            h4 {
              margin-bottom: 20px;
            }
          }
          .section_9 {
            margin-bottom: 100px;
            h4 {
              margin-bottom: 20px;
            }
            ul {
              list-style-type: disc !important;
              margin-left: 25px;
              margin-bottom: 20px;
              li {
                color: $color-black-platinum;
                font-family: $family-montserrat;
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 26px;
                text-align: justify;

                span {
                  font-weight: bold;
                }
              }
            }
          }
          .section_10 {
            display: flex;
            flex-direction: column;
            gap: 20px;
            margin-bottom: 40px;
          }
          .section_11 {
            h4 {
              margin-bottom: 20px;
            }
            div {
              > table {
                width: 100%;
                border-collapse: collapse;
                margin-bottom: 40px;
                td,
                th {
                  color: $color-black-platinum;
                  font-family: $family-montserrat;
                  padding-left: 8px;
                  border: 2px solid;
                  font-size: 16px;
                  line-height: 26px;
                }
                th {
                  width: 25%;
                  text-align: start;
                }
                td {
                  width: 25%;
                }
              }
            }
          }

          .section_12 {
            h4 {
              color: $color-black-platinum;
              font-family: $family-montserrat;
              font-style: normal;
              font-weight: 700;
              font-size: 16px;
              line-height: 26px;
              text-align: justify;
            }
          }
        }
      }

      .secondBlockTitle {
        display: flex;
        gap: 15px;
        align-items: center;
        justify-content: space-between;
      }
    }
  }
}

// Media css

@media only screen and (max-width: $mediaMaxWidth) {
  .about {
    padding: 0px 15px 18px 16px;
    min-height: 100%;
    display: flex;
    flex-direction: column;
    gap: 28px;
    > div:nth-child(2) {
      display: block;
    }
    .about_page {
      display: block;
      justify-content: flex-start;
      overflow: scroll;

      .aboutContainer {
        width: auto !important;
        margin: 0 auto;
        .policy_title {
          margin-bottom: 20px;

          h2 {
            font-size: 68px;
            line-height: 74px;
          }
        }
        .secondBlock {
          .desc {
            .section_11 {
              h4 {
                margin-bottom: 20px;
              }
              div {
                width: 100%;
                display: block;

                > table {
                  margin-bottom: 40px;
                }
              }
            }
          }
        }
      }
    }
  }
}
@media only screen and (max-width: $mediaBp1Width) {
  .about {
    gap: 0;
    .about_page {
      .aboutContainer {
        .policy_title {
          h2 {
            font-size: 68px;
            line-height: 74px;
          }
        }
        .secondBlock {
          .desc {
            .section_11 {
              div {
                display: block;

                > table {
                  margin-bottom: 40px;
                  td,
                  th {
                    font-size: 9px;
                    line-height: 16px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
